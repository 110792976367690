import PaperQrcodeMiddlePage from '@/page/qrcode/PaperQrcodeMiddlePage.vue'
export default [
  {
    path: '/paperQrcodeMiddlePage',
    name: 'PaperQrcodeMiddlePage',
    component: PaperQrcodeMiddlePage,
    meta: {
      title: '扫码页面',
    },
  },
]
