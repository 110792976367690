import { render, staticRenderFns } from "./CouponList.vue?vue&type=template&id=a513ba80&scoped=true"
import script from "./CouponList.vue?vue&type=script&lang=js"
export * from "./CouponList.vue?vue&type=script&lang=js"
import style0 from "./CouponList.vue?vue&type=style&index=0&id=a513ba80&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a513ba80",
  null
  
)

export default component.exports