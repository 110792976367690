<script>
export default {
  name: 'PracticeChooseModePopup',
  props: {
    visible: Boolean,
  },
  data() {
    return {
      mode: 'practice',
    }
  },
  computed: {
    innerVisible: {
      get() {
        return this.visible
      },
      set(v) {
        if (v) {
          this.mode = 'practice'
        }
        this.$emit('update:visible', v)
      },
    },
  },
}
</script>
<template>
  <div class="practiceChooseModePopup">
    <van-dialog v-model="innerVisible" title="选择练习模式" :showCancelButton="true" @confirm="$emit('confirm', mode)" @cancel="$emit('cancel')">
      <div class="practiceChooseModePopup-container">
        <van-radio-group v-model="mode" direction="horizontal">
          <van-radio name="practice">练习</van-radio>
          <van-radio name="selfTest">自测</van-radio>
        </van-radio-group>
      </div>
    </van-dialog>
  </div>
</template>
<style scoped>
.practiceChooseModePopup-container {
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
}
.practiceChooseModePopup-container /deep/ .van-radio-group {
  gap: 1rem;
}
</style>
