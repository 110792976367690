<template>
  <div class="testresults">
    <zv-nav-bar :title="pagename" v-if="!isPhoto" :clickLeft="clickLeft">
      <i class="fa fa-share-square-o" @click="sharePhoto" style="font-size: 0.53rem; color: #1989fa"></i>
    </zv-nav-bar>
    <van-nav-bar :title="pagename" v-else></van-nav-bar>
    <div class="content">
      <div class="results_bg"></div>
      <van-row class="pabox">
        <div class="cap_box">
          <img :src="imgurl" alt="" />
          <span>{{ canshow ? '完成考试' : '考试不通过' }}</span>
        </div>
        <div class="cap_title">恭喜{{ userInfo.realName }}完成答题！</div>
        <div :class="['cap_number', { no_pass: canshow }]" v-if="showScore">{{ itemData.mark }}<span class="number_type">分</span></div>
        <div class="cap_number--unfinished" v-else>阅卷中</div>
        <van-row class="results_botm_box clearfix">
          <van-col class="box_item" span="8">
            <div class="box_text">考试时长</div>
            <div class="box_num">{{ itemData.time }}<span class="num_type">分钟</span></div>
          </van-col>
          <van-col class="box_item" span="8">
            <div class="box_text">考试用时</div>
            <div class="box_num">{{ itemData.answerTime | formatDuring }}</div>
          </van-col>
          <van-col class="box_item" span="8">
            <div class="box_text">错题</div>
            <div class="box_num">{{ itemData.errorQuestionCount }}<span class="num_type">题</span></div>
          </van-col>
        </van-row>
      </van-row>
      <van-row class="test_list">
        <van-col span="24" v-for="(data, dataIndex) in questionRowList" :key="dataIndex">
          <div class="list_title">[{{ data.typeName }}]</div>
          <div class="list_info">
            <div v-for="(item, index) in data.data" :key="index" :class="{ block: true, red: item.type.indexOf('Tongue') > -1 ? item.answer : item.answerType === 'N', orange: !item.answerType }">
              {{ item.rowNo }}
            </div>
          </div>
        </van-col>
      </van-row>
      <van-row class="errors" v-if="itemData.canViewAnswer === 'Y'">
        <van-col span="24" @click="gotoPage(`/allCourses/wrongtopic/${$route.params.paperID}?examID=${$route.params.examID}`)"><van-icon name="orders-o" /><span>查看答案</span></van-col>
      </van-row>
    </div>
    <van-dialog v-model="showDialog" title="长按图片分享" confirmButtonText="关闭" @confirm="showDialog = false" width="100%">
      <img :src="photoSrc" />
    </van-dialog>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
export default {
  name: 'testresults',
  data() {
    return {
      pagename: '',
      canshow: true,
      itemData: {},
      questionRowList: [],
      isShow: false,
      photoSrc: '',
      showDialog: false,
      userInfo: {},
      isPhoto: false,
      needGoHome:false
    }
  },
  filters: {
    formatDuring(value) {
      if (!value) {
        return 0
      }
      let mss = value * 1000
      let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = (mss % (1000 * 60)) / 1000
      let str = hours + ' 小时 ' + minutes + ' 分钟 ' + seconds + ' 秒 '
      return str
    },
  },
  created() {
    this.getData()
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo)
    }
  },
  computed: {
    imgurl() {
      return this.canshow ? '/images/user/cap.png' : '/images/user/cap2x.png'
    },
    showScore() {
      return Number(this.itemData.subjectiveQuestionCount) <= 0 || Number(this.itemData.isScored)
    },
  },
  methods: {
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    getData() {
      this.$axios
        .get(`/tk/front/paper/examdetail?examID=${this.$route.params.examID}&paperID=${this.$route.params.paperID}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.pagename = res.data.data.examName.length > 24 ? res.data.data.examName.substring(0, 24) + '...' : res.data.data.examName
            this.isShowBtn = res.data.data.canViewAnswer
            this.canshow = res.data.data.isPass === 'Y'
            this.itemData = res.data.data
            this.needGoHome =(+res.data.data.isFaceAuth === 1)
            this.initAnswer(res.data.data.questionTypes, res.data.data.questionRowList)
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    initAnswer(types, lists) {
      for (let i = 0; i < types.length; i++) {
        let arr = []
        for (let j = 0; j < lists.length; j++) {
          if (lists[j].type === types[i].type) {
            arr.push(lists[j])
          }
        }
        types[i].data = arr
      }
      this.questionRowList = types
    },
    sharePhoto() {
      this.isPhoto = true
      if (this.photoSrc) {
        this.isPhoto = false
        this.showDialog = true
        return
      }
      this.$Toast.loading({
        message: '正在生成截图中...',
        forbidClick: true,
      })
      this.itemData.canViewAnswer = 'N'
      this.$nextTick(() => {
        const dom = document.getElementsByClassName('testresults')[0]
        html2canvas(dom).then((canvas) => {
          this.itemData.canViewAnswer = this.isShowBtn
          let url = canvas.toDataURL('image/png') // base64数据
          this.photoSrc = url
          this.showDialog = true
          this.isPhoto = false
          this.$Toast.clear()
        })
      })
    },
    clickLeft() {
      if (this.needGoHome) {
        this.$router.push('/')
      } else {
        if (window.history.length > 1) {
          this.$router.go(-1)
        } else {
        this.$router.push('/')
        }
      }
    }
  },
}
</script>
<style scoped>
.testresults >>> .van-ellipsis {
  overflow: auto;
  white-space: unset;
  text-overflow: unset;
}
</style>
<style lang="less" scoped>
.testresults {
  background-color: #f8f8f8;
  min-height: 100vh;
  /deep/ .van-icon.van-icon-photo-o {
    font-size: 0.42rem;
  }
  /deep/ .van-dialog {
    margin-top: 0.93rem;
  }
  /deep/ .van-dialog__content {
    max-height: 80vh;
    overflow-y: auto;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .results_bg {
    height: 8rem;
    background: url('../../../public/images/user/bg1.png') no-repeat;
    background-size: cover;
    position: relative;
  }
  .results_bg::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.54rem;
    content: '';
    height: 1.06rem;
    width: 100%;
    border-radius: 50%;
    background: #f8f8f8;
  }
  .pabox {
    text-align: center;
    width: 9.5rem;
    height: 8.5rem;
    background: url('../../../public/images/user/box.png') no-repeat;
    background-size: cover;
    position: absolute;
    top: 3rem;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    .cap_box {
      position: absolute;
      width: 2.05rem;
      height: 2.32rem;
      left: 50%;
      top: -8%;
      transform: translate(-50%, 0);
      span {
        position: absolute;
        font-family: AdobeHeitiStd-Regular;
        font-size: 0.33rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: -0.04rem;
        color: #ffffff;
        width: 100%;
        left: 50%;
        top: 79%;
        transform: translate(-50%, -50%);
        text-align: center;
      }
    }
    .cap_title {
      margin-top: 23%;
      font-family: PingFang-SC-Bold;
      font-size: 0.5rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #333333;
    }
    .cap_number {
      margin-top: 0.6rem;
      font-family: PingFang-SC-Heavy;
      font-size: 1.14rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0.06rem;
      color: #adadad;
      .number_type {
        font-family: AdobeHeitiStd-Regular;
        font-size: 0.45rem;
        font-weight: normal;
        letter-spacing: 0rem;
      }
    }
    .cap_number--unfinished {
      margin-top: 0.6667rem;
      font-size: 0.7467rem;
      font-weight: 500;
      color: #51d6d1;
    }
    .no_pass {
      color: #ffa202;
    }
    .results_botm_box {
      font-family: PingFang-SC-Medium;
      font-size: 0.27rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #2c2c2c;
      margin-top: auto;
      margin-bottom: 1rem;
      .box_item {
        text-align: center;
        .box_text {
          margin-bottom: 0.44rem;
        }
        .box_num {
          font-family: PingFang-SC-Bold;
          font-size: 0.32rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #2c2c2c;
          .num_type {
            font-family: PingFang-SC-Bold;
            font-size: 0.32rem;
            font-weight: normal;
            letter-spacing: 0rem;
          }
        }
      }
    }
  }
  .test_list {
    margin-top: 2rem;
    padding: 0 0.47rem;
    padding-bottom: 1.7rem;
    & > .van-col {
      margin-top: 0.5rem;
    }
    .list_title {
      font-family: PingFang-SC-Medium;
      font-size: 0.34rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.33rem;
      letter-spacing: 0rem;
      color: #292929;
    }
    .list_info {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.2rem;
      .block {
        width: 1rem;
        height: 1rem;
        background-color: #1578f2;
        border-radius: 0.11rem;
        font-family: PingFang-SC-Medium;
        font-size: 0.34rem;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #ffffff;
        text-align: center;
        line-height: 1rem;
        margin: 0.2rem 0.24rem;
      }
      .red {
        background-color: #ff4d4d;
      }
      .orange {
        background-color: #fc9c16;
      }
    }
  }
  .errors {
    font-family: PingFang-SC-Medium;
    font-size: 0.34rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #292929;
    text-align: center;
    background: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    & > .van-col {
      padding: 0.4rem 0;
      position: relative;
      & > .van-icon {
        top: 1px;
      }
      & > span {
        margin-left: 0.09rem;
      }
    }
    & > .van-col::after {
      content: '';
      display: block;
      width: 0.01rem;
      height: 0.57rem;
      background-color: #e0e0e0;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
    }
    & > .van-col:last-child::after {
      display: none;
    }
  }
}
</style>
