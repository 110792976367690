<script>
import { Toast, Dialog } from 'vant'
import PracticeChooseModePopup from '../nzzone/components/PracticeChooseModePopup.vue'
export default {
  name: 'paperQrcodeMiddlePage',
  components: {
    PracticeChooseModePopup,
  },
  data() {
    return {
      paperType: '',
      paperID: 0,
      loadingInstance: null,
      currentPractice: {},
      practiceChooseModePopupVisible: false,
    }
  },
  methods: {
    async handleRouteQuery() {
      this.loadingInstance = Toast.loading('正在加载中...')
      switch (this.paperType) {
        case 'practice':
          this.getPracticeDetail()
          break
        case 'exam':
          this.getExamDetail()
          break
      }
    },
    async getExamDetail() {
      try {
        const res = await this.$axios.get(`/tk/front/member/personalcenter/exam?examID=${this.paperID}`)
        if (res.data.status === 1) {
          await this.goAnswer(res.data.data || {})
        } else {
          this.goHome(res.data.message)
        }
      } catch (error) {
        this.goHome()
        console.log(error)
      } finally {
        this.loadingInstance && this.loadingInstance.close()
      }
    },
    async getPracticeDetail() {
      try {
        const res = await this.$axios.get(`/tk/front/member/personalcenter/practice?paperID=${this.paperID}`)
        if (res.data.status === 1) {
          await this.gotoPractice(res.data.data || {})
        } else {
          this.goHome(res.data.message)
        }
      } catch (error) {
        this.goHome()
        console.log(error)
      } finally {
        this.loadingInstance && this.loadingInstance.close()
      }
    },
    gotoPractice(item) {
      if (item.isSelfTest && !(+item.isSelfTest === 1 && !item.canSelfTest)) {
        this.goAnswerRequest({
          ...item,
          practiceMode: +item.isSelfTest,
        })
        return
      }
      if (!item.canSelfTest) {
        this.goAnswerRequest({
          ...item,
          practiceMode: 0,
        })
        return
      }
      this.currentPractice = item
      this.practiceChooseModePopupVisible = true
    },
    goAnswer(item) {
      if (item.isStartExam === 0) {
        return this.goHome('考试未开始！')
      }
      if (item.isOverExam === 1 && item.isExamined === 'N') {
        return this.goHome('考试已过期！')
      }

      if (item.status === 'Y') {
        // 前往考试完成页面
        this.$router.replace({
          path: `/study/testresults/${item.productID}/${item.ID}`,
        })
        return
      }
      if (item.isIdCardAuth === 1 && item.isFaceAuth === 0) {
        let info = JSON.parse(localStorage.userInfo)
        if (info.isChecked !== 1) {
          Dialog.confirm({
            title: '身份认证提示',
            message: '未进行身份认证，请先去身份认证',
          })
            .then(() => {
              this.$router.replace({ path: `/user/userinfo/info` })
            })
            .catch(() => {
              this.goHome()
              return
            })
        } else {
          this.goAnswerRequest(item)
        }
      } else if (item.isIdCardAuth === 1 && item.isFaceAuth === 1) {
        this.faceAuthRequest(item)
      } else {
        this.goAnswerRequest(item)
      }
    },
    // 人脸认证
    async faceAuthRequest(item) {
      let info = JSON.parse(localStorage.userInfo)
      if (info.isChecked !== 1) {
        Dialog.confirm({
          title: '身份认证提示',
          message: '未进行身份认证，请先去身份认证',
        })
          .then(() => {
            this.$router.replace({ path: `/user/userinfo/info` })
          })
          .catch(() => {
            this.goHome()
            return
          })
      } else {
        this.$axios
          .get(`/course/front/member/face/auth/issuccess?type=ClassDetailExam&id=${item.ID}`)
          .then((res) => {
            if (res.data.status === 1) {
              if (res.data.data.isSuccess !== 1) {
                Dialog.confirm({
                  title: '温馨提示',
                  message: '即将进入人脸认证，是否继续？',
                })
                  .then(() => {
                    this.getFaceAuthUrl(item, false)
                  })
                  .catch(() => {
                    this.goHome()
                    return
                  })
              } else {
                this.goAnswerRequest(item)
              }
            } else {
              this.goHome(res.data.message)
              return
            }
          })
          .catch(() => {
            this.goHome()
            return
          })
      }
    },
    getFaceAuthUrl(item) {
      let url = ''
      if (window.location.href.indexOf('#') > 0) {
        url = window.location.href.split('#')[1]
      }
      // 获取用户结果
      this.$axios
        .get(`/course/front/member/face/auth?type=ClassExam&id=${item.ID}&examRedirectUrl=${encodeURIComponent(url)}`)
        .then((res) => {
          if (res.data.status === 1) {
            if (!res.data.data.bizToken && res.data.data.isSuccess === 1) {
              this.goAnswerRequest(item)
            } else {
              window.location.href = res.data.data.url
            }
          } else {
            this.goHome(res.data.message)
            return
          }
        })
        .catch(() => {
          this.goHome()
          return
        })
    },
    async goAnswerRequest(item) {
      try {
        const res = await this.$axios.get(
          !this.isUndef(item.practiceMode)
            ? `/tk/front/paper/${item.ID}/checkandgeneratekey?isSelfTest=${item.practiceMode || 0}`
            : `/tk/front/paper/${item.productID}/checkandgeneratekey?examID=${item.ID}`
        )
        if (res.data.status === 1) {
          // 前往答题页面
          this.$router.replace({
            path: `/allCourses/examinationpaper?id=${item.ID}&isPhoto=${item.isPhoto}&key_success=${
              res.data.data.key_success
            }&pagename=${!this.isUndef(item.practiceMode) ? '练习' : ''}&qrCode=true`,
          })
        } else {
          this.goHome(res.data.message)
        }
      } catch (error) {
        this.goHome()
        return error
      } finally {
        this.loadingInstance && this.loadingInstance.close()
      }
    },
    isUndef(v) {
      return v === undefined || v === null
    },
    goHome(message) {
      this.loadingInstance && this.loadingInstance.close()
      this.loadingInstance = this.$Toast.loading(message || '即将返回首页...')
      setTimeout(() => {
        this.loadingInstance.close()
        this.$router.replace('/')
      }, 1000)
    },
  },
  created() {
    const { type = '', id = 0 } = this.$route.query
    this.paperType = type
    this.paperID = id
    const shareInfo = {
        disabled: true,
    }
      // mixins
    this.wechatShare(shareInfo)
    this.handleRouteQuery()

  },
}
</script>
<template>
  <div>
    <PracticeChooseModePopup
      :visible.sync="practiceChooseModePopupVisible"
      @confirm="
        goAnswerRequest({
          practiceMode: $event === 'practice' ? 0 : 1,
          ...currentPractice,
        })
      "
      @cancel="goHome('')"
    />
  </div>
</template>
