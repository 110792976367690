<template>
  <div class="studyreport">
    <zv-nav-bar :title="pagename">
      <i class="fa fa-share-square-o" @click="sharePhoto" style="font-size: 0.53rem; color: #1989fa"></i>
    </zv-nav-bar>
    <div class="report_bg">
      <div class="img_top">
        <img src="../../../public/images/user/cap2.png" />
      </div>
      <van-row>
        <van-col span="24" class="top_title">{{ itemData.name }}</van-col>
      </van-row>
      <van-row class="user_info">
        <van-row class="user_img">
          <img :src="itemData.avatar" />
        </van-row>
        <van-row class="user_box">
          <van-row class="user_name">{{ itemData.realName }}</van-row>
          <van-row class="study_box">
            <van-col span="12" class="study_item">
              <div class="study_text">得分</div>
              <div class="study_num" v-if="showScore">{{ itemData.mark }}</div>
              <div class="study_num study_num--unfinished" v-else>阅卷中</div>
            </van-col>
            <van-col span="12" class="study_item">
              <div class="study_text">试卷总分</div>
              <div class="study_num">{{ itemData.totalScore }}</div>
            </van-col>
          </van-row>
        </van-row>
      </van-row>

      <van-row class="user_info">
        <van-row class="user_title">
          <van-col class="title_text">课堂表现</van-col>
        </van-row>
        <van-row class="user_box">
          <div class="class_time">
            <div class="class_text">听课时长</div>
            <div class="time_info">
              <div class="info_l">
                <span>{{ parseInt(itemData.studyLength / 60) }}分钟{{ parseInt(itemData.studyLength % 60) }}秒</span
                ><span>/{{ parseInt(itemData.totalLength / 60) }}分钟{{ parseInt(itemData.totalLength % 60) }}秒</span>
              </div>
              <div class="info_r">
                <span class="cyan">学习完</span>
                <span class="red">未学习</span>
              </div>
            </div>
            <div class="prog_bar">
              <div class="container">
                <div
                  class="skills html"
                  :style="{ width: (parseInt(itemData.studyLength) / parseInt(itemData.totalLength)) * 100 + '%' }"
                ></div>
              </div>
            </div>
          </div>
          <div class="class_exercise">
            <div class="exercise_text">课堂练习</div>
            <div class="exercise_box">
              <div class="circle_box">
                <!-- <van-circle v-model="currentRate" :rate="parseInt(itemData.correctRate * 100)" :stroke-width="100" :speed="100" layer-color="#51d6d1" :clockwise="false">
                  <div class="num">{{parseInt(itemData.correctRate * 100)}}<span>%</span></div>
                  <div class="num_text">正确率</div>
                </van-circle> -->
                <z-circle :currentRate="currentRate">
                  <div class="num">{{ parseInt(itemData.correctRate * 100) }}<span>%</span></div>
                  <div class="num_text">正确率</div>
                </z-circle>
                <div class="circle_text">超越了{{ itemData.rank }}的同学</div>
              </div>
              <div class="circle_type">
                <div>
                  <span class="circle_cyan">正确</span>
                  <span class="circle_num">{{ itemData.rightCount }}题</span>
                </div>
                <div>
                  <span class="circle_cyan">错误</span>
                  <span class="circle_num">{{ itemData.wrongCount }}题</span>
                </div>
                <div>
                  <span class="circle_cyan">未答</span>
                  <span class="circle_num">{{ itemData.noAnswerCount }}题</span>
                </div>
                <div>
                  <span class="circle_cyan">主观</span>
                  <span class="circle_num">{{ itemData.subjectiveQuestionCount }}题</span>
                </div>
                <div>
                  <span class="circle_cyan">口语</span>
                  <span class="circle_num">{{ itemData.tongueQuestionCount }}题</span>
                </div>
              </div>
            </div>
            <van-row class="class_botm">
              <van-col span="12" class="study_class_box">
                <div class="study_time">学习时长</div>
                <div class="study_class_num">
                  <span>{{ itemData.studyLength | formatValue }}</span>
                </div>
              </van-col>

              <van-col span="12" class="study_class_box">
                <div class="study_time">练习次数</div>
                <div class="study_class_num">
                  <span>{{ itemData.counts }}</span
                  >次
                </div>
              </van-col>
            </van-row>
            <van-row
              class="class_table"
              v-if="itemData.questionTypesDistribution && itemData.questionTypesDistribution.length > 0"
            >
              <div class="exercise_text">错题题型分布</div>
              <table>
                <tr class="thstyle">
                  <th>题型</th>
                  <th>错题数</th>
                  <th>占比</th>
                </tr>
                <tr
                  v-for="(item, index) in itemData.questionTypesDistribution"
                  :key="index"
                  :class="{ sky: (index + 1) % 2 === 0 }"
                >
                  <td>{{ item.questionTypeName }}</td>
                  <td>{{ item.count }}</td>
                  <td>{{ item.percentage }}</td>
                </tr>
              </table>
            </van-row>
            <van-row v-else class="class_table">
              <div class="exercise_text">错题题型分布</div>
              <table>
                <tr class="thstyle">
                  <th>题型</th>
                  <th>错题数</th>
                  <th>占比</th>
                </tr>
                <tr>
                  <td colspan="3">暂无数据</td>
                </tr>
              </table>
            </van-row>
            <van-row
              class="class_table"
              v-if="itemData.classifyDistribution && itemData.classifyDistribution.length > 0"
            >
              <div class="exercise_text">错题所在分类</div>
              <table>
                <tr class="thstyle">
                  <th>一级分类</th>
                  <th>二级分类</th>
                  <th>错题数</th>
                  <th>卷面分占比</th>
                </tr>
                <tr
                  v-for="(item, index) in itemData.classifyDistribution"
                  :key="index"
                  :class="{ sky: (index + 1) % 2 === 0 }"
                >
                  <td>{{ item.classifyLevelOne }}</td>
                  <td>{{ item.classifyLevelTwo }}</td>
                  <td>{{ item.count }}</td>
                  <td>{{ item.percentage }}</td>
                </tr>
              </table>
            </van-row>
            <van-row v-else class="class_table">
              <div class="exercise_text">错题所在分类</div>
              <table>
                <tr class="thstyle">
                  <th>一级分类</th>
                  <th>二级分类</th>
                  <th>错题数</th>
                  <th>卷面分占比</th>
                </tr>
                <tr>
                  <td colspan="4">暂无数据</td>
                </tr>
              </table>
            </van-row>
          </div>
        </van-row>
      </van-row>

      <van-row class="report_btn" v-if="isShowBtn">
        <van-col :span="showTestButton ? 8 : 12">
          <van-button
            round
            type="info"
            @click="gotoPage(`/allCourses/wrongtopic/${$route.params.paperID}?paperType=${$route.query.paperType}`)"
            >查看答案</van-button
          >
        </van-col>
        <van-col :span="showTestButton ? 8 : 12" v-if="showTestButton">
          <van-button round class="againbtn" @click="practiseAgain(true)">{{
            itemData.canSelfTest === 1 ? '立即自测' : '重新自测'
          }}</van-button>
        </van-col>
        <van-col :span="showTestButton ? 8 : 12">
          <van-button round class="againbtn" @click="practiseAgain(false)"
            >{{ itemData.practiced ? '再次' : '开始' }}练习</van-button
          >
        </van-col>
      </van-row>
    </div>
    <van-dialog
      v-model="showDialog"
      title="长按图片分享"
      confirmButtonText="关闭"
      @confirm="showDialog = false"
      width="100%"
    >
      <img :src="photoSrc" />
    </van-dialog>
  </div>
</template>

<script>
import ZCircle from '@/components/ZCircle.vue'
import html2canvas from 'html2canvas'
export default {
  name: 'studyreport',
  data() {
    return {
      itemData: {},
      pagename: '学习报告',
      timenumber: 60,
      currentRate: 0,
      rate: 50,
      isShowBtn: true,
      showDialog: false,
      photoSrc: '',
    }
  },
  components: {
    'z-circle': ZCircle,
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0)
    },
    showScore() {
      return Number(this.itemData.subjectiveQuestionCount) <= 0 || Number(this.itemData.isScored)
    },
    showTestButton() {
      return this.itemData.canSelfTest > 0
    },
  },
  filters: {
    formatValue(value) {
      if (!value) {
        return '暂无数据'
      }
      let m = parseInt(value / 60) < 10 ? '0' + parseInt(value / 60) + '分' : parseInt(value / 60) + '分'
      let s = value % 60 < 10 ? '0' + (value % 60) + '秒' : (value % 60) + '秒'
      return m + s
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$axios
        .get(
          `/tk/front/paper/practicedetail?courseID=${this.$route.params.courseID}&paperID=${
            this.$route.params.paperID
          }&answerID=${this.$route.query.answerID || undefined}`
        )
        .then((res) => {
          if (res.data.status === 1) {
            this.itemData = res.data.data
            this.currentRate = res.data.data.correctRate || 0
            let userInfo = JSON.parse(localStorage.userInfo)
            this.itemData.realName = userInfo.realName
            this.itemData.avatar = userInfo.avatar
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    practiseAgain(isSelfTest) {
      this.$axios
        .get(
          isSelfTest
            ? `/tk/front/paper/${this.$route.params.paperID}/checkandgeneratekey?isSelfTest=${isSelfTest ? 1 : 0}`
            : `/tk/front/paper/${this.$route.params.paperID}/checkandgeneratekey?courseID=${this.$route.params.courseID}`
        )
        .then((res) => {
          if (res.data.status === 1) {
            // 前往答题页面
            this.$router.push({
              path: `/allCourses/examinationpaper?id=${this.$route.params.courseID}&isPhoto=false&key_success=${res.data.data.key_success}`,
            })
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    sharePhoto() {
      if (this.photoSrc) {
        this.showDialog = true
        return
      }
      this.$Toast.loading({
        message: '正在生成截图中...',
        forbidClick: true,
      })
      this.isShowBtn = false
      this.$nextTick(() => {
        const dom = document.getElementsByClassName('report_bg')[0]
        html2canvas(dom, { useCORS: true }).then((canvas) => {
          this.isShowBtn = true
          let url = canvas.toDataURL('image/png') // base64数据
          this.photoSrc = url
          this.showDialog = true
          this.$Toast.clear()
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.studyreport {
  /deep/ .van-dialog {
    margin-top: 0.93rem;
  }
  /deep/ .van-dialog__content {
    max-height: 80vh;
    overflow-y: auto;
  }
  table {
    border-collapse: collapse; //表格的边框合并，如果相邻，则共用一个边框。
    border-spacing: 0px; //设置行与单元格边框的间距。
  }
  img {
    width: 100%;
    height: 100%;
  }
  .report_bg {
    min-height: 28rem;
    background: url('../../../public/images/user/bg.png') no-repeat;
    background-size: cover;
    position: relative;
    .img_top {
      width: 4rem;
      margin: 0 auto;
      padding-top: 0.8rem;
    }
    .top_title {
      text-align: center;
      font-family: PingFang-SC-Bold;
      font-size: 0.34rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #ffffff;
      margin-top: 0.28rem;
    }
    .user_info {
      background: #ffffff;
      border-radius: 0.11rem;
      margin: 0 0.44rem;
      padding: 0.11rem;
      margin-top: 1rem;
      text-align: center;
      position: relative;
      .user_img {
        width: 1.6rem;
        height: 1.6rem;
        background-color: #d2d2d2;
        border: solid 0.06rem #dae9ff;
        border-radius: 50%;
        margin: 0 auto;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
          border-radius: 50%;
        }
      }
      .user_box {
        border-radius: 0.11rem;
        border: solid 0.02rem #60a2fd;
        .user_name {
          margin-top: 0.81rem;
          font-family: PingFang-SC-Heavy;
          font-size: 0.45rem;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #111111;
          padding: 0.28rem 0;
        }
        .study_box {
          margin-bottom: 0.68rem;
          .study_item {
            position: relative;
            .study_text {
              font-family: PingFang-SC-Medium;
              font-size: 0.32rem;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0rem;
              color: #646464;
            }
            .study_num {
              font-size: 0.68rem;
              line-height: 0.68rem;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0rem;
              color: #222222;
              margin-top: 0.33rem;
            }
            .study_num--unfinished {
              color: #51d6d1;
            }
          }
          .study_item::after {
            content: '';
            display: block;
            width: 0.01rem;
            height: 0.9rem;
            background-color: #e0e0e0;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
          }
          .study_item:last-child::after {
            display: none;
          }
        }
        .class_time {
          text-align: left;
          margin: 0 0.36rem;
          padding-top: 0.59rem;
          padding-bottom: 1rem;
          border-bottom: 1px solid #eeeeee;
          .class_text {
            font-family: PingFang-SC-Bold;
            font-size: 0.36rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #111111;
          }
          .time_info {
            display: flex;
            justify-content: space-between;
            margin-top: 0.47rem;
            .info_l {
              font-family: PingFang-SC-Medium;
              font-size: 0.32rem;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0rem;
              color: #222222;
              width: 4.53rem;
              & > span:last-child {
                color: #999999;
              }
            }
            .info_r {
              font-family: PingFang-SC-Medium;
              font-size: 0.32rem;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0rem;
              color: #666666;
              span {
                margin-left: 0.3rem;
              }
              .cyan::before {
                content: '';
                display: inline-block;
                width: 0.2rem;
                height: 0.2rem;
                border-radius: 50%;
                background-color: #51d6d1;
                margin-right: 0.15rem;
              }
              .red::before {
                content: '';
                display: inline-block;
                width: 0.2rem;
                height: 0.2rem;
                border-radius: 50%;
                background-color: #ff493b;
                margin-right: 0.15rem;
              }
            }
          }
          .prog_bar {
            border-radius: 0.09rem;
            height: 0.18rem;
            margin-top: 0.25rem;
            .container {
              width: 100%;
              background-color: #ff493b;
              border-radius: 0.09rem;
            }
            .html {
              border-radius: 0.09rem 0 0 0.09rem;

              background-color: #51d6d1;
              height: 0.18rem;
            }
          }
        }
        .class_exercise {
          text-align: left;
          padding-top: 0.45rem;
          .exercise_text {
            margin: 0 0.36rem;
            font-family: PingFang-SC-Bold;
            font-size: 0.36rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #111111;
          }
          .exercise_box {
            margin: 0 0.36rem;
            display: flex;
            padding: 0.6rem 0;
            & > div {
              width: 50%;
              text-align: center;
            }
            .circle_box {
              position: relative;
              .van-circle {
                justify-content: center;
                display: flex;
                flex-direction: column;
                margin: 0 auto;
                width: 2.66rem;
                height: 2.66rem;
              }
              .num {
                font-family: PingFang-SC-Heavy;
                font-size: 0.45rem;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0rem;
                color: #0f0f0f;
                span {
                  font-size: 0.27rem;
                  font-weight: normal;
                  letter-spacing: 0rem;
                  color: #0f0f0f;
                }
              }
              .num_text {
                font-family: PingFang-SC-Regular;
                font-size: 0.22rem;
                font-weight: normal;
                letter-spacing: 0rem;
                color: #989898;
              }
              .circle_text {
                font-family: PingFang-SC-Medium;
                font-size: 0.3rem;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0rem;
                color: #333333;
                margin-top: 0.25rem;
              }
            }
            .circle_box::after {
              content: '';
              display: inline-block;
              width: 0.01rem;
              height: 1.39rem;
              background: #e8e8e8;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translate(0, -50%);
            }
            .circle_type {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              font-family: PingFang-SC-Medium;
              font-size: 0.32rem;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0rem;
              color: #333333;
              .circle_cyan::before {
                content: '';
                width: 0.2rem;
                height: 0.2rem;
                border-radius: 50%;
                background: #51d6d1;
                display: inline-block;
                margin-right: 0.15rem;
              }
              .circle_num {
                color: #666666;
                margin-left: 0.5rem;
              }
            }
          }
          .class_botm {
            margin: 0 0.36rem;
            border-bottom: 0.01rem solid #eeeeee;
            .study_class_box {
              text-align: center;
              border-right: solid 0.01rem #e8e8e8;
              margin-bottom: 0.39rem;
              .study_time {
                font-family: PingFang-SC-Medium;
                font-size: 0.34rem;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0rem;
                color: #333333;
                margin-bottom: 0.41rem;
              }
              .study_class_num {
                font-family: PingFang-SC-Medium;
                font-size: 0.27rem;
                font-weight: normal;
                letter-spacing: 0rem;
                color: #222222;
                span {
                  font-size: 0.41rem;
                }
              }
            }
            .study_class_box:last-child {
              border: 0;
            }
          }
          .class_table {
            padding-bottom: 0.61rem;
            border-bottom: 0.01rem solid #eeeeee;
            .exercise_text {
              padding: 0.38rem 0;
            }
            table {
              width: 100%;
              tr {
                text-align: center;
                th {
                  padding: 0.39rem 0;
                  font-family: PingFang-SC-Medium;
                  font-size: 0.3rem;
                  font-weight: normal;
                  font-stretch: normal;
                  letter-spacing: 0rem;
                  color: #1069eb;
                }
                td {
                  font-family: PingFang-SC-Medium;
                  font-size: 0.3rem;
                  font-weight: normal;
                  font-stretch: normal;
                  letter-spacing: 0rem;
                  color: #333333;
                  padding: 0.39rem 0;
                }
              }
              .thstyle {
                background-color: #eaf3ff;
              }
              .sky {
                background-color: #eaf3ff;
              }
              .typeblue {
                color: #51d6d1;
              }
              .typegray {
                color: #999999;
              }
              .typered {
                color: #ff493b;
              }
            }
          }
        }
      }
      .user_title {
        position: absolute;
        background-color: #ffe585;
        border-radius: 0.34rem;
        padding: 0.17rem 0.47rem;
        left: 50%;
        transform: translate(-50%, -50%);
        .title_text {
          font-family: PingFang-SC-Bold;
          font-size: 0.34rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #dd6311;
        }
      }
    }
    .report_btn {
      padding: 0.67rem 0;
      & > .van-col {
        text-align: center;
      }

      button {
        padding: 0.3rem 0.82rem;
      }
      .againbtn {
        background-color: #ffe585;
        color: #dd3311;
      }
    }
  }
  .not-data {
    color: #111111;
    text-align: center;
    font-size: 0.36rem;
  }
}
</style>
